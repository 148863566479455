import React from 'react';
import { ReactComponent as Square } from './square.svg';
import { withRouter } from 'react-router-dom';

import {
  Wrapper,
  ThumbnailWrapper,
  ContentWrapper,
  Category,
  TitleWrapper,
  Title,
  Image,
} from './style';

const PortfolioItem = ({
  slug,
  title,
  thumbnail,
  category,
  color,
  history,
  location,
}) => {
  return (
    <Wrapper
      className={color}
      onClick={() => history.push(`${location.pathname}/${slug}`)}
    >
      <Square />
      <ThumbnailWrapper>
        <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"></div>
        <Image src={thumbnail} alt={title} />
      </ThumbnailWrapper>
      <ContentWrapper>
        <Category>{category}</Category>
        <TitleWrapper>
          <Title>{title}</Title>
        </TitleWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default withRouter(PortfolioItem);
