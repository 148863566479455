import styled from 'styled-components';
import tw from 'twin.macro';

const BurgerButton = styled.button`
  ${tw`inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500`}
`;

const BurgerWrapper = styled.div`
  ${tw` -mr-2 flex items-center md:hidden`}
`;

const Header = styled.div`
  ${tw`flex items-center justify-between w-full md:w-auto`}
`;

const HeaderWrapper = styled.div`
  ${tw`flex items-center flex-grow flex-shrink-0 lg:flex-grow-0`}
`;

const MenuWrapper = styled.div`
  ${tw`hidden md:block md:ml-10 md:pr-4`}
`;

const Nav = styled.nav`
  ${tw`relative flex items-center justify-between sm:h-10 lg:justify-start`}
`;

const NavWrapper = styled.div`
  ${tw`pt-6 px-4 sm:px-6 lg:px-8`}
`;

export {
  Nav,
  NavWrapper,
  Header,
  HeaderWrapper,
  MenuWrapper,
  BurgerWrapper,
  BurgerButton,
};
