import React from 'react';
import iedImg from '../../assets/lebaran-card/ied.jpg'
import {WrapperLarge} from './style'

const LebaranCardLarget = ({ name }) => {
  return (
      <WrapperLarge id="kartu-lebaran-large">
        <div className="flex-1">
        <img className="object-cover w-full" src={iedImg} alt="Happy IED 1441H" />
        </div>
        <div className="flex-1 text-center md:text-left">
          <div className="pt-10 py-5">
            <h4 className="text-lg mt-2">
              <span className="salam">ٱلسَّلَامُ عَلَيْكُمْ وَرَحْمَةُ ٱللَّٰهِ وَبَرَكَاتُهُ</span>
            </h4>
            <h2 className="mt-8 text-gray-600">Selamat Hari Raya</h2>
            <h2 className="text-gray-600">Idul Fitri 1441H</h2>
            <p className="text-gray-600 mt-5 p-3">
              Semoga Allah menerima seluruh amal ibadah ramadhan dan
              senantiasa mencurahkan kasih sayang Nya.
                  </p>
            <h5 className="font-bold mt-10">
              <span role="img" aria-label="hearth"> ❤️</span> {name} & Keluarga
            </h5>
          </div>
        </div>
      </WrapperLarge>
  )
}

export default LebaranCardLarget;
