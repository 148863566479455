import styled from 'styled-components';
import tw from 'twin.macro';
import { ReactComponent as BackIcon } from '../../assets/back.svg';

const Card = styled.div``;

const CardTitle = styled.div`
  ${tw`px-4 py-5 border-b border-gray-200 sm:px-6 flex`}
`;

const CardBody = styled.div`
  ${props =>
    props.flex
      ? tw`p-0 flex flex-wrap items-center justify-center`
      : tw`w-full`}
`;

const Title = styled.h3`
  ${tw`text-lg leading-6 font-medium text-gray-900`}
`;

const Subtitle = styled.p`
  ${tw`mt-1 max-w-2xl text-sm leading-5 text-gray-500`}
`;

const BackButton = styled(BackIcon)`
  ${tw`rounded-full shadow h-10 w-10 justify-center p-3 mr-5`}
`;

export { Card, CardTitle, CardBody, Title, Subtitle, BackButton };
