import styled from 'styled-components';
import tw from 'twin.macro';

const Wrapper = styled.div`
  ${tw`flex-shrink-0 m-6 relative overflow-hidden rounded-lg max-w-xs shadow-lg cursor-pointer`}
`;

const ThumbnailWrapper = styled.div`
  ${tw`relative pt-10 px-10 flex items-center justify-center`}
`;

const ContentWrapper = styled.div`
  ${tw`relative text-white px-6 pb-6 mt-6`}
`;

const Category = styled.span`
  ${tw`block opacity-50 -mb-1 text-sm`}
`;

const TitleWrapper = styled.div`
  ${tw`flex justify-between`}
`;

const Title = styled.span`
  ${tw`block font-bold text-xl`}
`;

const Image = styled.img`
  ${tw`relative w-40 rounded`}
`;

export {
  Wrapper,
  ThumbnailWrapper,
  ContentWrapper,
  Category,
  TitleWrapper,
  Title,
  Image,
};
