import styled from 'styled-components';
import tw from 'twin.macro';

const Section = styled.div`
  ${tw`mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-24`}
`;

const Wrapper = styled.div`
  ${tw`sm:text-center lg:text-left`}
`;

const NameTitle = styled.h2`
  ${tw`text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl`}
`;

const Highlight = styled.span`
  ${tw`text-orange-500`}
`;

const Description = styled.p`
  ${tw`mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0`}
`;

const CTAWrapper = styled.div`
  ${tw`mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start`}
`;

export { Section, Wrapper, NameTitle, Highlight, Description, CTAWrapper };
