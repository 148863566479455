import styled from 'styled-components';
import tw from 'twin.macro';

const List = styled.li`
  ${tw`pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5`}
`;

const Title = styled.span`
  ${tw`ml-2 flex-1 w-0 truncate`}
`;

const TitleWrapper = styled.div`
  ${tw`w-0 flex-1 flex items-center`}
`;

const Link = styled.a`
  ${tw`font-medium text-indigo-600 hover:text-indigo-500`}
`;

const LinkWrapper = styled.div`
  ${tw`ml-4 flex-shrink-0`}
`;

export { List, Title, TitleWrapper, Link, LinkWrapper };
