import React from 'react';
import Thumbnail from '../../components/ThumbnailSection';
import Content from '../../components/ContentSection';
import Container from '../../template/Container';

const Home = () => {
  return (
    <Container>
      <Content />
      <Thumbnail />
    </Container>
  );
};

export default Home;
