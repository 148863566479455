import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import html2canvas from 'html2canvas';
import ButtonLink from '../../components/Button';
import downloadImg from '../../assets/lebaran-card/download.svg';
import LebaranCardLarge from '../../components/lebaranCard/large';
import LebaranCard from '../../components/lebaranCard';
import Container from '../../template/Container';

import template from './template';

import {
  Card,
  CardTitle,
  CardBody,
  Title,
  Subtitle,
  BackButton,
  WrapperCard,
} from './style';

const LebaranCardPage = () => {
  const [name, setName] = useState('(nama_kamu)');
  const [selected, setSelected] = useState(1);
  const [alignType, setAlignType] = useState('potrait');

  const ProcessCard = (name) => {
    const fileName = name.replace(/ /g, '_');
    const selectedCardData =
      alignType === 'landscape'
        ? 'kartu-lebaran-large'
        : `kartu-portait-${selected}`;
    const element = window.document.getElementById(selectedCardData);

    window.scrollTo(0, 0);

    html2canvas(element, {
      logging: false,
      scrollX: 0,
      scrollY: -window.scrollY,
    }).then((canvas) => {
      let tempcanvas = document.createElement('canvas');
      tempcanvas.width = 1080;
      tempcanvas.height = 1920;
      let context = tempcanvas.getContext('2d');
      context.drawImage(canvas, 465, 40, 465, 524, 0, 0, 465, 524);

      let link = document.createElement('a');
      link.href = canvas
        .toDataURL('image/png')
        .replace(/^data:image\/png/, 'data:application/octet-stream');
      link.download = `kartu_lebaran_${fileName}.png`;
      link.click();
    });
  };

  return (
    <Container>
      <Card>
        <CardTitle>
          <Link to="/">
            <BackButton />
          </Link>
          <div>
            <Title>Kartu Lebaran</Title>
            <Subtitle>Buat Kartu Ucapan Lebaran Versi Kamu.</Subtitle>
          </div>
        </CardTitle>
        <CardBody>
          <div className="p-5">
            <div className="mt-2">
              <label className="text-gray-600 font-bold">Dari : </label>
              <input
                type="email"
                onChange={(e) => setName(e.target.value)}
                name=""
                id=""
                placeholder="e.g: Agus Fikri Suyuthi"
                className="border p-2 w-full mt-3"
              ></input>
            </div>
            <div className="mt-5 text-center">
              <div className="bg-gray-200 text-sm text-gray-500 leading-none border-2 border-gray-200 rounded-full inline-flex">
                <button
                  onClick={() => setAlignType('potrait')}
                  className={`${
                    alignType === 'potrait' && 'active'
                  } inline-flex items-center transition-colors duration-300 ease-in focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-l-full px-4 py-2`}
                  id="grid"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="fill-current w-4 h-4 mr-2"
                  >
                    <rect x="3" y="3" width="7" height="7"></rect>
                    <rect x="14" y="3" width="7" height="7"></rect>
                    <rect x="14" y="14" width="7" height="7"></rect>
                    <rect x="3" y="14" width="7" height="7"></rect>
                  </svg>
                  <span>potrait</span>
                </button>
                <button
                  disabled
                  className={`${
                    alignType === 'landscpae' && 'active'
                  } inline-flex items-center transition-colors duration-300 ease-in focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-r-full px-4 py-2`}
                  id="list"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="fill-current w-4 h-4 mr-2"
                  >
                    <rect x="3" y="3" width="7" height="7"></rect>
                    <rect x="14" y="3" width="7" height="7"></rect>
                    <rect x="14" y="14" width="7" height="7"></rect>
                    <rect x="3" y="14" width="7" height="7"></rect>
                  </svg>
                  <span>landscape (SOON)</span>
                </button>
              </div>
            </div>
            {alignType === 'potrait' ? (
              <WrapperCard>
                {Object.keys(template).map((templateKey) => (
                  <LebaranCard
                    clickListener={setSelected}
                    name={name}
                    type={templateKey}
                    selected={selected === parseInt(templateKey)}
                    key={templateKey}
                  />
                ))}
              </WrapperCard>
            ) : (
              <div className="mt-5 p-6">
                <LebaranCardLarge name={name} />
              </div>
            )}
            <div className="mt-4">
              <ButtonLink
                color="blue"
                size="small"
                onClick={() => ProcessCard(name)}
              >
                <img src={downloadImg} width="18" alt="Download" />
                <span className="ml-2 pt-1 text-white font-bold hover:hidden">
                  Download Ucapan
                </span>
              </ButtonLink>
            </div>
          </div>
        </CardBody>
      </Card>
    </Container>
  );
};

export default withRouter(LebaranCardPage);
