import styled from 'styled-components';
import tw from 'twin.macro';

const Wrapper = styled.div`
${tw`md:flex-1 md:flex justify-center py-10`}
`

const WrapperLarge = styled.div`
  ${tw`md:flex bg-white shadow-lg rounded-lg`}
`
export {Wrapper, WrapperLarge}
