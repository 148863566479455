import React from 'react';

import Nav from '../Nav';
import Title from '../Title';

import { Section, Wrapper } from './style';

const ContentSection = () => {
  return (
    <Section>
      <Wrapper>
        <Nav />
        <Title />
      </Wrapper>
    </Section>
  );
};

export default ContentSection;
