import React from 'react';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import Link from '../Link';

const Menu = ({ toggleMenu }) => {
  return (
    <div>
      <div className="px-5 pt-4 flex items-center justify-between">
        <div></div>
        <div className="-mr-2">
          <button
            onClick={() => toggleMenu()}
            type="button"
            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
          >
            <CloseIcon />
          </button>
        </div>
      </div>
      <div className="px-2 pt-2 pb-3">
        <Link to="/" mobile="true" active="true">
          Home
        </Link>
        <Link to="/portfolio" mobile="true">
          Portofolio
        </Link>
        <Link to="/kartu-lebaran" mobile="true">
          Kartu Lebaran
        </Link>
      </div>
    </div>
  );
};

export default Menu;
