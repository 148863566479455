import qiklan from '../../assets/qiklan.png';
import informaticsExpo from '../../assets/expo.png';
import kutim from '../../assets/kutai.png';
import ppidKutim from '../../assets/ppid.png';
import marcommFTI from '../../assets/marcomm-fti.png';
import uii from '../../assets/uii.png';
import ebblCalculator from '../../assets/ebblcalculator.png';
import jalaLogo from '../../assets/jala-logo.png'

const INITIAL_STATE = {
  data: [
    {
      id: 1,
      slug: 'qiklan-indonesia',
      title: 'Qiklan Indonesia',
      thumbnail: qiklan,
      category: 'Social Agency',
      color: 'bg-red-400',
      detail: {
        client: 'Qiklan Indonesia',
        description:
          'Qiklan Indonesia merupakan sebuah platform yang membantu UMKM memasarkan produknya di media online melalui bantuan influencer atau bisa juga dikatakan sebagai platform penghubung antara UMKM dan Influencer.',
        contribution: 'Front End Developer',
        duration: '3 months',
        technologies: ['laravel', 'jquery'],
        attachments: [],
      },
    },
    {
      id: 2,
      slug: 'informatics-expo',
      title: 'Informatics Expo',
      thumbnail: informaticsExpo,
      category: 'College',
      color: 'bg-blue-400',
      detail: {
        client: 'Prgram Studi S1 Informatika UII',
        description:
          'Informatics-expo merupakan sebuah portal karya untuk mahasiswa S1 Informatika di Universitas Islam Indonesia.',
        contribution: 'Front End Developer',
        duration: '3 months',
        technologies: [
          'laravel',
          'Jquery',
          'Google Drive API',
          'Google oAuth API',
        ],
        attachments: [
          {
            title: 'Live Demo',
            cta: ['preview', 'https://informatics-expo.id'],
          },
        ],
      },
    },
    {
      id: 3,
      slug: 'kutim-web-apps',
      title: 'Kutim Web Apps',
      thumbnail: kutim,
      category: 'Government',
      color: 'bg-orange-600',
      detail: {
        client: 'Pemerintah Kabupaten Kutai Timur',
        description:
          'Qikalan merupakan situs yang menghubungkan antara influencer dan umkm',
        contribution: 'Front End Developer',
        duration: '3 months',
        technologies: ['laravel', 'jquery'],
        attachments: [
          {
            title: 'Live Demo',
            cta: ['preview', 'https://www.kutaitimurkab.go.id/'],
          },
        ],
      },
    },
    {
      id: 4,
      slug: 'ppid-kutim',
      title: 'PPID Kutim',
      thumbnail: ppidKutim,
      category: 'Government',
      color: 'bg-orange-400',
      detail: {
        client: 'Pemerintah Kabupaten Kutai Timur',
        description:
          'PPID Kab. Kutai timur merupakan situs Keterbukaan publik milik Kabupaten Kutai Timur.',
        contribution: 'Front End Developer',
        duration: '3 months',
        technologies: ['laravel', 'jquery'],
        attachments: [
          {
            title: 'Live Demo',
            cta: ['preview', 'ppid.kutaitimurkab.go.id/'],
          },
        ],
      },
    },
    {
      id: 5,
      slug: 'sim-marcomm-fti-uii',
      title: 'SIM MarcommFTI UII',
      thumbnail: marcommFTI,
      category: 'College',
      color: 'bg-indigo-400',
      detail: {
        client: 'Marketing & Communications FTI UII',
        description:
          'SIM MarcommFTI UII merupakan sebuah sistem informasi manajemen logistik di lingkungan marcomm FTI UII',
        contribution: 'Full Stack Developer',
        duration: '3 months',
        technologies: ['laravel', 'jquery'],
        attachments: [],
      },
    },
    {
      id: 6,
      slug: 'sim-unit-bisnis-uii',
      title: 'SIM Unit Bisnis UII',
      thumbnail: uii,
      category: 'College',
      color: 'bg-green-400',
      detail: {
        client: 'DPSD UII ',
        description: '-',
        contribution: 'Front End Developer',
        duration: '3 months',
        technologies: ['laravel', 'jquery'],
        attachments: [],
      },
    },
    {
      id: 7,
      slug: 'ebbl-calculator',
      title: 'EBBL Calculator',
      thumbnail: ebblCalculator,
      category: 'College',
      color: 'bg-purple-400',
      detail: {
        client: 'Program Studi Magister Informatika UII',
        description:
          'EBBL Calculator merupakan aplikasi pengukur Estimasi Berat Lahir Bayi dari Data Pengukuran USG dengan parameter AC(Abdominal Circumferencial), BPD(Biparietal Diameter), HC(Head Circumferencial), dan FL (Femur Length)',
        contribution: 'Front End Developer',
        duration: '2 week',
        technologies: ['VanillaJS', 'RStudio'],
        attachments: [
          {
            title: 'Live Demo',
            cta: ['preview', 'http://ebblcalculator.com/'],
          },
        ],
      },
    },
    {
      id: 8,
      slug: 'portfolio-dosen-fti-uii',
      title: 'Portfolio Dosen FTI-UII',
      thumbnail: uii,
      category: 'College',
      color: 'bg-green-400',
      detail: {
        client: 'Fakultas Teknologi Industri FTI UII',
        description:
          'Portofolio dosen ini dibutuhkan untuk kepentingan masing-masing dosen dan jurusan. Portofolio dibutuhkan dosen untuk kenaikan jabatan dan sertifikasi. Di lain pihak, portofolio dosen dibutuhkan oleh jurusan sebagai bagian penting dalam penilaian akreditasi di tingkat jurusan dan untuk menyusun Ekuivalensi Nilai Mengajar Penuh (EWMP)',
        contribution: 'Front End Developer',
        duration: '3 months',
        technologies: ['laravel', 'jquery'],
        attachments: [],
      },
    },
    {
      id: 9,
      slug: 'jala-apps',
      title: 'JALA Apps',
      thumbnail: jalaLogo,
      category: 'Startup',
      color: 'bg-blue-600',
      detail: {
        client: 'JALA Tech',
        description:
          'JALA merupakan salah startup di Yogyakarta yang memberikan solusi digital terpadu untuk membantu meningkatkan hasil panen, memastikan keuntungan serta keberlanjutan budidaya tambak udang. Kombinasi alat ukur kualitas air terhubung internet serta sistem analisis berbasis data untuk budidaya lebih baik.',
        contribution: 'Front End Engineer (Internship)',
        duration: '6 months',
        technologies: ['Laravel', 'VueJS'],
        attachments: [
          {
            title: 'Live Demo',
            cta: ['preview', 'https://app.jala.tech'],
          },
        ],
      },
    },
  ],
};

const portfoliosReducer = (state = INITIAL_STATE, { type }) => {
  switch (type) {
    default:
      return state;
  }
};

export default portfoliosReducer;
