import styled from 'styled-components';
import tw from 'twin.macro';

const Wrapper = styled.div`
  ${tw`lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2`}
`;

const Image = styled.img`
  ${tw`h-56 w-full object-cover sm:h-56 md:h-64 lg:w-full lg:h-full`}
`;

export { Wrapper, Image };
