import React from 'react';
import Menu from './menu';

const MobileNav = ({ toggleMenu }) => {
  return (
    <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
      <div className="rounded-lg shadow-md">
        <div className="rounded-lg bg-white shadow-xs overflow-hidden">
          <Menu toggleMenu={toggleMenu} />
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
