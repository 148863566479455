import React from 'react';

import Attachments from '../Attachments';

import {
  ListLabel,
  Row,
  RowTitle,
  RowValue,
  Thumbnail,
  ListWrapper,
  AttachmentsListWrapper,
} from './style';

const PortfolioDetail = ({ data }) => {
  const {
    client,
    description,
    contribution,
    duration,
    technologies,
    attachments,
  } = data.detail;
  return (
    <div>
      <dl>
        <Row>
          <RowTitle>Thumbnail</RowTitle>
          <RowValue>
            <Thumbnail src={data.thumbnail} alt={data.title} />
          </RowValue>
        </Row>
        <Row white>
          <RowTitle>Project Name</RowTitle>
          <RowValue>{data.title}</RowValue>
        </Row>
        <Row>
          <RowTitle>Client</RowTitle>
          <RowValue>{client}</RowValue>
        </Row>
        <Row white>
          <RowTitle>Description</RowTitle>
          <RowValue>{description}</RowValue>
        </Row>
        <Row>
          <RowTitle>My Contribution</RowTitle>
          <RowValue>{contribution}</RowValue>
        </Row>
        <Row white>
          <RowTitle>Duration</RowTitle>
          <RowValue>{duration}</RowValue>
        </Row>
        <Row>
          <RowTitle>Technology</RowTitle>
          <RowValue>
            <ListWrapper>
              {technologies &&
                technologies.map((tech, index) => (
                  <ListLabel key={index}>{tech}</ListLabel>
                ))}
            </ListWrapper>
          </RowValue>
        </Row>
        {attachments.length > 0 && (
          <Row white>
            <RowTitle>Attachments</RowTitle>
            <RowValue>
              <AttachmentsListWrapper>
                {attachments.map((attachment, index) => (
                  <Attachments key={index} {...attachment} />
                ))}
              </AttachmentsListWrapper>
            </RowValue>
          </Row>
        )}
      </dl>
    </div>
  );
};

export default PortfolioDetail;
