import React from 'react';

import { List, Title, TitleWrapper, Link, LinkWrapper } from './style';

const Attachments = ({ title, cta }) => {
  const [name, url] = cta;
  return (
    <List>
      <TitleWrapper>
        <svg
          className="flex-shrink-0 h-5 w-5 text-gray-400"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
            clipRule="evenodd"
          />
        </svg>
        <Title>{title}</Title>
      </TitleWrapper>
      <LinkWrapper>
        <Link href={url} target="_blank" rel="noopener noreferrer">
          {name}
        </Link>
      </LinkWrapper>
    </List>
  );
};

export default Attachments;
