import styled from 'styled-components';
import tw from 'twin.macro';

const Section = styled.div`
  ${tw`max-w-screen-xl mx-auto`}
`;

const Wrapper = styled.div`
  ${tw`relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-xl xl:max-w-2xl lg:w-6/12 lg:pb-24 xl:pb-32`}
`;

export { Section, Wrapper };
