import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import PortfolioItem from '../../components/PortfolioItem';
import Container from '../../template/Container';

import {
  Card,
  CardTitle,
  CardBody,
  Title,
  Subtitle,
  BackButton,
} from './style';

const Portfolio = ({ portofolios }) => {
  return (
    <Container>
      <Card>
        <CardTitle>
          <Link to="/">
            <BackButton />
          </Link>
          <div>
            <Title>Portfolio</Title>
            <Subtitle>See my Portfolio below.</Subtitle>
          </div>
        </CardTitle>
        <CardBody flex>
          {portofolios &&
            portofolios.map((portfolio) => (
              <PortfolioItem key={portfolio.id} {...portfolio} />
            ))}
        </CardBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  portofolios: state.portfolios.data,
});

export default connect(mapStateToProps)(Portfolio);
