import React from 'react';
import { Wrapper, Image } from './style';
import { LazyImage } from 'react-lazy-images';

import lazy from '../../assets/fikri_waiting.jpg';

const ThumbnailSection = () => {
  return (
    <Wrapper>
      <LazyImage
        src={require('../../assets/fikri.jpg')}
        alt="AGUS FIKRI SUYUTHI"
        debounceDurationMs={400}
        placeholder={({ imageProps, ref }) => (
          <Image ref={ref} src={lazy} alt="AGUS FIKRI SUYUTHI" />
        )}
        actual={({ imageProps }) => (
          <Image {...imageProps} alt="AGUS FIKRI SUYUTHI" />
        )}
      />
    </Wrapper>
  );
};

export default ThumbnailSection;
