import React, { useState } from 'react';
import MobileNav from './mobile';
import { ReactComponent as BurgerIcon } from '../../assets/burger.svg';

import { Logo } from './logo';
import {
  Nav,
  NavWrapper,
  Header,
  HeaderWrapper,
  MenuWrapper,
  BurgerWrapper,
  BurgerButton,
} from './style';

import Link from '../Link';

const NavMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(prevValue => !prevValue);
  return (
    <div>
      <NavWrapper>
        <Nav>
          <HeaderWrapper>
            <Header>
              <Logo href="/" />
              <BurgerWrapper>
                <BurgerButton onClick={() => toggleMenu()}>
                  <BurgerIcon />
                </BurgerButton>
              </BurgerWrapper>
            </Header>
          </HeaderWrapper>
          <MenuWrapper>
            <Link to="/" active="true">
              Home
            </Link>
            <Link to="/portfolio">Portfolio</Link>
            <Link to="/kartu-lebaran">Kartu Lebaran</Link>
          </MenuWrapper>
        </Nav>
      </NavWrapper>

      {isOpen && <MobileNav toggleMenu={toggleMenu} />}
    </div>
  );
};

export default NavMenu;
