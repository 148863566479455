import template1 from '../../assets/lebaran-card/template/1.png'
import template2 from '../../assets/lebaran-card/template/2.png'
import template3 from '../../assets/lebaran-card/template/3.png'
import template4 from '../../assets/lebaran-card/template/4.png'

const template = {
  1: template1,
  2: template2,
  3: template3,
  4: template4
}

export default template;
