import styled from 'styled-components';
import tw from 'twin.macro';

const color = {
  inverse: tw`text-orange-700 bg-orange-100 hover:text-orange-600 hover:bg-orange-100 focus:border-orange-300`,
  primary: tw`text-white bg-orange-600 hover:bg-orange-500`,
  light: tw`bg-white hover:bg-gray-200 text-gray-800`,
  gray: tw`bg-gray-200 hover:bg-gray-300 text-gray-800`,
  blue: tw`bg-blue-500 hover:bg-blue-700 text-white`,
}

const size = {
  small : tw`justify-center text-center rounded-lg px-4 md:px-5 xl:px-4 py-3 md:py-4 xl:py-3 md:text-lg xl:text-base font-semibold leading-tight shadow-md`,
  large: tw`justify-center w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md focus:outline-none focus:shadow-outline md:py-4 md:text-lg md:px-10`
}

const getButtonColor = props => props.color ? color[props.color] : color['primary'];

const getButtonSize = props => props.size ? size[props.size] : size['large'];

const ButtonLink = styled.a`
  display: flex;
  cursor: pointer;
  ${getButtonSize}
  ${getButtonColor}
`;

export default ButtonLink;
