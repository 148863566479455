import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import PortfolioDetail from '../../components/PortfolioDetail';

import {
  Card,
  CardTitle,
  CardBody,
  Title,
  Subtitle,
  BackButton,
} from './style';

const PortfolioDetailPage = ({ data }) => {
  return (
    <Card>
      <CardTitle>
        <Link to="/portfolio">
          <BackButton />
        </Link>
        <div>
          <Title>{data.title}</Title>
          <Subtitle>{data.detail.client}</Subtitle>
        </div>
      </CardTitle>
      <CardBody>
        <PortfolioDetail data={data} />
      </CardBody>
    </Card>
  );
};
const mapStateToProps = (state, otherProps) => ({
  data: state.portfolios.data.find(
    item => item.slug === otherProps.match.params.slug,
  ),
});

export default withRouter(connect(mapStateToProps)(PortfolioDetailPage));
