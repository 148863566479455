import { Link as LinkRoute } from 'react-router-dom';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

const LinkMobile = css`
  ${tw`mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:text-gray-900 focus:bg-gray-100`}
`;

const LinkCommon = css`
  ${tw`ml-8 font-medium focus:outline-none`}
`;

const LinkActivated = css`
  ${tw`text-orange-600 hover:text-orange-900 focus:text-orange-700`}
`;

const LinkInactive = css`
  ${tw`text-gray-500 hover:text-gray-900 focus:text-gray-900`}
`;

const Link = styled(LinkRoute)`
  ${props => (props.mobile ? LinkMobile : LinkCommon)}
  ${props => (props.active ? LinkActivated : LinkInactive)}
`;

export default Link;
