import React from 'react';
import template from '../../pages/LebaranCard/template'

const lebaranCard = ({name, type, selected, clickListener}) => {
  const currentType = parseInt(type)

  return (
    <div className="p-2">
    <div
      onClick={() => clickListener(currentType)}
      className={`${selected ? 'rounded-lg border-4 border-teal-500' : ''} mt-5`}>
    <div
      id={`kartu-portait-${type}`}
      className="cursor-pointer sm:w-full max-w-xs bg-white shadow-lg rounded-lg">
      <img className="w-full object-cover" src={template[type]} alt="kartu-lebaran"/>
      <div className="text-center px-4 py-2 bg-gray-300">
        <h1 className="text-gray-700 font-bold text-sm">
          <span role="img" aria-label="hearth" className="text-red-300"> ❤️</span>{name}
        </h1>
      </div>
    </div>
    </div>
    </div>
  )
}

export default lebaranCard
