import React from 'react';

import { Section, Wrapper, Footer } from './style';

const Container = ({ children }) => {
  return (
    <Section>
      <Wrapper>{children}</Wrapper>
      <Footer>
        Crafted with{' '}
        <span style={{ marginLeft: 2 }} role="img" aria-label="love">
          ❤️
        </span>
      </Footer>
    </Section>
  );
};

export default Container;
