import styled from 'styled-components';
import tw from 'twin.macro';

const Section = styled.div`
  ${tw`w-full mx-auto p-2 lg:px-48 lg:py-20`}
`;

const Wrapper = styled.div`
  ${tw`relative rounded-lg w-full items-center bg-white shadow-xl overflow-hidden`}
`;

const Footer = styled.div`
  ${tw`mt-5 text-gray-600 hover:text-gray-800 text-center`}
`;

export { Section, Wrapper, Footer };
