import styled from 'styled-components';
import tw from 'twin.macro';

const ListLabel = styled.li`
  ${tw`m-2 bg-orange-200 p-2 ml-2`}
`;

const Row = styled.div`
  ${tw`px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}
  ${props => (props.white ? tw`bg-white` : tw`bg-gray-100 `)}
`;

const RowTitle = styled.dd`
  ${tw`text-sm leading-5 font-medium text-gray-500`}
`;

const RowValue = styled.dt`
  ${tw`mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2`}
`;

const Thumbnail = styled.img`
  ${tw`relative w-40`}
`;

const ListWrapper = styled.ul`
  ${tw`flex flex-wrap`}
`;

const AttachmentsListWrapper = styled.ul`
  ${tw`border border-gray-200 rounded-md`}
`;

export {
  ListLabel,
  Row,
  RowTitle,
  RowValue,
  Thumbnail,
  ListWrapper,
  AttachmentsListWrapper,
};
