import styled from 'styled-components';

const Logo = styled.a`
  -webkit-box-align: center;
  align-items: center;
  border: 0;
  color: #fff;

  :before {
    border: 3px solid #dd6b20;
    position: absolute;
    border-radius: 2px;
    width: 35px;
    height: 35px;
    content: '';
    top: 2px;
  }

  :after {
    font-weight: 700;
    font-size: 1.2rem;
    content: 'FIK';
    color: #dd6b20;
    position: absolute;
    width: 18px;
    height: 34px;
    left: 14px;
    top: 9px;
    overflow: hidden;
    -webkit-transition: all 0.5s cubic-bezier(0.18, 0.89, 0.31, 1.16);
    transition: all 0.5s cubic-bezier(0.18, 0.89, 0.31, 1.16);
  }

  :hover:after {
    width: 28px;
    left: 5px;
  }
`;

export { Logo };
