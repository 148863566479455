import React from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import Home from './pages/Homepage';
import Portfolio from './pages/Portfolio';
import PortfolioDetail from './pages/Portfolio/show';
import LebaranCardPage from './pages/LebaranCard';

import './App.css';

function App({ data }) {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route
          exact
          path="/portfolio/:slug"
          render={(props) =>
            data(props.match.params.slug) ? (
              <PortfolioDetail />
            ) : (
              <Redirect to="/" />
            )
          }
        />
        <Route exact path="/kartu-lebaran" component={LebaranCardPage} />
        <Route exact path="*" render={(props) => <Redirect to="/" />} />
      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => ({
  data: (slug) => state.portfolios.data.find((item) => item.slug === slug),
});

export default connect(mapStateToProps)(App);
